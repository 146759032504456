import React from 'react';
import './header.scss';
function Header() {
  return (
    <header className="page-header hidden">
      <div>
        <h1 className="page-header__main-text">Jack Li's Portfolio</h1>
      </div>
    </header>
  );
}

export default Header;

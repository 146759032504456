import React from 'react';
import './errorPage.scss';

function ErrorPage() {
  return (
    <div className="error-page-holder">
      <div className="fluid-container">
        <div className="error-page__wrapper">
          <div className="error-page__content">
            <h1>404</h1>
            <p>You're lost.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;

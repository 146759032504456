import React from 'react';
import { ReactComponent as EmailIcon } from 'assets/icons/email-icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin-icon.svg';
import { ReactComponent as GithubIcon } from 'assets/icons/github-icon.svg';
import { sectionAnchorId } from 'components/aboutMe/AboutMe';
import './footer.scss';

function Footer() {
  return (
    <footer className="footer-holder">
      <div className="fluid-container">
        <div className="footer__wrapper">
          <div className="footer__flex">
            <FooterContent />
          </div>
        </div>
      </div>
    </footer>
  );
}

function FooterContent() {
  return (
    <>
      <div className="footer__sitemap-col">
        <span>
          <a href={`/#${sectionAnchorId.MAIN.id}`}>Intro</a>
        </span>
        <span>
          <a href={`/#${sectionAnchorId.INTRO.id}`}>About Me</a>
        </span>
        <span>
          <a href={`/#${sectionAnchorId.BE_PROJECTS.id}`}>Projects</a>
        </span>
        <span>
          <a href={`/#${sectionAnchorId.FE_PROJECTS.id}`}>Portfolio</a>
        </span>
      </div>
      <div className="footer__social-col">
        <div className="footer__social-media">
          <a
            href="https://www.linkedin.com/in/jack-jingxi-li/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>
              <LinkedinIcon width={22} height={22} />
            </span>
          </a>
          <a
            href="https://github.com/Jackiebibili"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>
              <GithubIcon width={22} height={22} />
            </span>
          </a>
          <a href="mailto:jacky984479215@outlook.com">
            <span>
              <EmailIcon width={22} height={22} />
            </span>
          </a>
        </div>
        <div className="footer__copyright-entry">
          Copyright &copy; 2024 Jingxi L. All Rights Reserved.
        </div>
      </div>
    </>
  );
}

export default Footer;

import React from 'react';
import { beProjects, feProjects } from 'constants/content/projects';
import './verticalThread.scss';

export default function VerticalThread({ content }) {
  const generateProjectLabelsList = (list) =>
    list.map((item) => (
      <li key={item.id}>
        <a href={`/#${item.id}`}>{item.shortcut}</a>
      </li>
    ));
  return (
    <div className="vertical-thread-holder">
      <div className="thread__grid-layout">
        <div>
          <p>Start</p>
        </div>
        <div>
          <div>{content.BE.start}</div>
        </div>
        <div>
          <div>{content.FE.start}</div>
        </div>
        <div>
          <p>Skills</p>
        </div>
        <div>
          <div>{content.BE.skills}</div>
        </div>
        <div>
          <div>{content.FE.skills}</div>
        </div>
        <div>
          <p>Projects</p>
        </div>
        <div>
          <ul>{generateProjectLabelsList(beProjects)}</ul>
        </div>
        <div>
          <ul>{generateProjectLabelsList(feProjects)}</ul>
        </div>
      </div>
    </div>
  );
}

export const intro = {
  mainText1: 'Hello',
  mainText2: "I'm Jingxi (Jack),",
  mainText3: 'with 2+ years of experience',
  mainText4: 'Seeking Software Engineer Role',
  // secondaryText1: 'CS senior at The University of Texas at Dallas',
  // secondaryText2: (
  //   <>
  //     Anticipated to graduate by <u>December 2022</u>
  //   </>
  // ),
  secondaryText1: (
    <>
      Available to start in the <u>spring of 2025</u>
    </>
  ),
};

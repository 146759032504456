import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-solid.svg';
import './pageDownBtn.scss';

const btnHiddenAwait = 2000;

function PageDownBtn(props) {
  const { anchors, includeStart = false } = props;
  const [anchorList, setAnchorList] = useState([]);
  const [clientHeight, setClientHeight] = useState(0);
  const [curSection, setCurSection] = useState(0);
  const [timer, setTimer] = useState(null);

  const getOneAnchorTopAndBottom = (id) => {
    const elem = document.getElementById(id);
    const rect = elem.getBoundingClientRect();
    return { top: rect.top, bottom: rect.bottom };
  };
  const getClientHeight = () =>
    window.innerHeight || document.documentElement.clientHeight;

  const isBeforeFullView = (rect) => {
    return rect.top > 2;
  };
  const isAfterFullView = (rect, clientHeight) => {
    return rect.bottom < clientHeight;
  };
  const getCurSectionIdx = useCallback(() => {
    const res = anchorList.reduce(
      (prev, cur, index) => {
        if (prev.pass) return prev;
        if (isBeforeFullView(cur)) {
          return { pass: true, idx: prev.idx };
        } else if (isAfterFullView(cur, clientHeight)) {
          return { pass: false, idx: index };
        } else {
          // in full view
          return { pass: true, idx: index };
        }
      },
      { pass: false, idx: includeStart ? 0 : -1 },
    );
    setCurSection(res.idx);
  }, [anchorList, clientHeight, includeStart]);

  const handleScroll = useCallback(() => {
    setAnchorList(() => anchors.map((id) => getOneAnchorTopAndBottom(id)));
    setClientHeight(() => getClientHeight());
  }, [anchors]);
  const handleSetTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(() => setTimeout(() => setTimer(false), btnHiddenAwait));
  }, [timer]);

  const mustBtnHide = () =>
    [-1, anchorList.length - 1].some((val) => val === curSection);

  const handleClickScroll = () => {
    if (mustBtnHide()) {
      return;
    }

    const nextElem = document.getElementById(anchors[curSection + 1]);
    const distanceFromTop =
      window.pageYOffset + nextElem.getBoundingClientRect().top;
    window.scroll({ top: distanceFromTop, behavior: 'smooth' });
  };

  const isBtnHidden = () => {
    return timer === false || mustBtnHide();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleSetTimer);
    return () => window.removeEventListener('scroll', handleSetTimer);
  }, [handleSetTimer]);

  useEffect(() => {
    getCurSectionIdx();
  }, [getCurSectionIdx]);

  return (
    <div className="page-down-btn-holder">
      <button
        onClick={handleClickScroll}
        className={cn('page-down-btn', { hidden: isBtnHidden() })}
        style={mustBtnHide() ? { display: 'none' } : {}}
      >
        <ArrowDownIcon />
      </button>
    </div>
  );
}

export default PageDownBtn;

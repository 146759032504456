import React from 'react';
import GalleryCard from './galleryCard/GalleryCard';
import './gallerySection.scss';

function GallerySection({ projects }) {
  return (
    <div className="gallery-section-holder">
      {projects.map((card, idx) => (
        <GalleryCard key={idx} {...card} />
      ))}
    </div>
  );
}

export default GallerySection;

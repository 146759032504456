import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from 'components/common/header/Header';
import Footer from 'components/common/footer/Footer';
import AboutMe from 'components/aboutMe/AboutMe';
import ErrorPage from 'components/common/errorPage/ErrorPage';
import './App.scss';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <main>
          <Switch>
            <Route path="/" exact component={AboutMe} />
            <Route component={ErrorPage} />
          </Switch>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import cn from 'classnames';
import { sectionAnchorId } from 'components/aboutMe/AboutMe';
import './navMenu.scss';

function NavMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, []);

  return (
    <nav className="nav-menu-holder">
      <button
        className="nav-menu__toggle-btn"
        onClick={(e) => {
          e.stopPropagation();
          setIsMenuOpen((status) => !status);
        }}
      >
        toggle menu
      </button>
      <div
        className={cn('nav-menu__dropdown-container', {
          'nav-menu__dropdown-container--is-open': isMenuOpen,
        })}
      >
        <ul className="nav-menu__dropdown-list">
          <li>
            <a href={`#${sectionAnchorId.MAIN.id}`}>Intro</a>
          </li>
          <li>
            <a href={`#${sectionAnchorId.INTRO.id}`}>About Me</a>
          </li>
          <li>
            <a href={`#${sectionAnchorId.BE_PROJECTS.id}`}>Projects</a>
          </li>
          <li>
            <a href={`#${sectionAnchorId.FE_PROJECTS.id}`}>Portfolio</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavMenu;
